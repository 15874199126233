*,
*:before,
*:after{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
}

.input-field-error{
    color: crimson;
    font-size: 13px;
    font-weight: bold;
    margin-top: 2px;
}

.ui.input{
    width: 100%;
}

.ui.search .prompt{
    border-radius: .28571429rem;
}

.accounts-input{
    margin-bottom: 20px;
        
    label{
        display: inline-block;
        margin-bottom: 5px;
    }

    &-radio{
        margin-right: 20px;
        display: inline-block;
    }
}

.util-bold{
    font-weight: bold;
}

.padded-content{
    padding: 50px 20px;
}

.ui.menu{
    margin: 0rem;
    border-radius: 0px;
}